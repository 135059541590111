
const data = {
    faqs: [
      {
        title: 'What harsh truths do you prefer to ignore?',
        content: 'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim.'
      },
      {
        title: 'What harsh truths do you prefer to ignore?',
        content: 'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim.'
      },
      {
        title: 'What harsh truths do you prefer to ignore?',
        content: 'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim.'
      }
    ]
    
  };
  export default data;
  